/** Permet de modifier le comportement des éléments dans la page selon le défilement de la souris */
export default class Scrolly {
  /**
   * Création des variables globales pour les options du scroll
   * @param {HTMLElement} element - Élément
   */
  constructor(element) {
    this.element = element;
    this.options = {
      rootMargin: '0px',
    };
    this.init();
  }

  /** Création de l'observateur et le faire observer les éléments désirés */
  init() {
    const observer = new IntersectionObserver(
      this.watch.bind(this),
      this.options
    );

    const items = this.element.querySelectorAll('[data-scrolly]');
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      observer.observe(item);
    }
  }

  /** Si l'observateur voit un élément HTML avec un data-scrolly
   * @param {HTMLElement} entries - Éléments à voir par l'observateur
   * @param {HTMLElement} observer - Observateur
   */
  watch(entries, observer) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const target = entry.target;

      if (entry.isIntersecting) {
        target.classList.add('is-active');

        if ('noRepeat' in target.dataset) {
          observer.unobserve(target);
        }
      } else {
        target.classList.remove('is-active');
      }
    }
  }
}
