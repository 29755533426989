/* const openModale = function (e) {
  e.preventDefault();
  const target = document.querySelector(e.target.getAttribute('href'));
  target.style.display = null;
  target.removeAttribute('aria-hidden');
  target.setAttribute('aria-modal', 'true');
}; */

const btnColor = document.querySelector('.toggle_color');
const body = document.body;

btnColor.addEventListener('click', function () {
  btnColor.classList.toggle('light');
  btnColor.classList.toggle('dark');
  body.classList.toggle('darkMode');
  body.classList.toggle('lightMode');
});

/* const btnsModale = document.querySelectorAll('.btn_modale');
const swiperImages = document.querySelectorAll('.swiper_image');
const modale = document.querySelector('.modale');
const modaleImage = document.querySelector('.modale_image');

btnsModale.addEventListener('click', openModale());

function openModale() {
  for (let i = 0; i < btnsModale.length; i++) {
    swiperImages.forEach((image) => {
      modale.classList.add('visible');
      modaleImage.src = image.src;
      modaleImage.alt = image.alt;
    });

    modale.addEventListener('click', closeModale());
  }
}

function closeModale() {
  modale.classList.remove('visible');
} */

/* function openModale() {
  const chemin = this.previousElementSibling.getAttribute('src');
  const image = document.createElement('img');

  image.setAttribute('src', chemin);
  image.classList.add('zoom');

  modale.appendChild(image);
  modale.classList.add('visible');

  image.addEventListener('click', closeModale);
}

function closeModale() {
  modale.classList.remove('visible');
} */
