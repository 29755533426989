import ComponentFactory from './ComponentFactory';
import Icons from './utils/Icons';

class Main {
  constructor() {
    this.init();
  }

  init() {
    document.documentElement.classList.add('has-js');
    new ComponentFactory();
    Icons.load();
  }
}
new Main();

const formulaire = document.getElementById('myForm');
if (formulaire) {
  formulaire.addEventListener('submit', function (e) {
    e.preventDefault();

    const formData = new FormData(this);

    const formDataObj = {};
    formData.forEach((value, key) => {
      formDataObj[key] = value;
    });

    const formDataJSON = JSON.stringify(formDataObj);

    fetch(
      'https://send-email.amambo008.workers.dev/?emailReciver=justinharrison_createurinteractif@outlook.com',
      {
        method: 'POST',
        body: formDataJSON,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("Envoi de l'e-mail réussi");

          document.getElementById('myForm').reset();

          //document.querySelector('.form__confirmation').style.display = 'block';

          //document.getElementById('myForm').style.display = 'none';
        } else {
          throw new Error(
            'Échec de la requête. Code de statut : ' + response.status
          );
        }
      })
      .then((data) => {
        console.log('Succès !');
      })
      .catch((error) => {
        console.error(error);
      });
  });
}
