/** Permet de faire disparaître le Header quand on a suffisamment descendu dans la page avec le scroll de la souris */
export default class Header {
  /**
   * Création des variables globales du Header
   * @param {HTMLElement} element - Élément
   */
  constructor(element) {
    this.element = element;
    this.scrollPosition = 0;
    this.lastScrollPosition = 0;
    this.variant = this.element.dataset.variant;
    this.html = document.documentElement;
    this.init();
    this.initNavMobile();
  }

  /** Cache ou montre le header si le header a un data-hiding ou non */
  init() {
    if ('hiding' in this.element.dataset) {
      window.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  /** Faire en sorte que le header réapparaisse quand on scroll vers le haut et appelle les méthodes setHeaderState et setDirectionState */
  onScroll() {
    this.lastScrollPosition = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;
    this.setHeaderState();
    this.setDirectionState();
  }

  /** Ajoute ou enlève la classe header-is-hidden selon la valeur ded scrollPosition */
  setHeaderState() {
    const scrollHeight = document.scrollingElement.scrollHeight;

    if (this.scrollPosition > scrollHeight) {
      this.html.classList.add('header-is-hidden');
    } else {
      this.html.classList.remove('header-is-hidden');
    }
  }

  /** Ajout des classes appropriées si on scroll vers le haut ou le bas */
  setDirectionState() {
    if (this.scrollPosition >= this.lastScrollPosition) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.remove('is-scrolling-down');
      this.html.classList.add('is-scrolling-up');
    }
  }

  /** Ajoute un écouteur d'événement sur le menu hamburger */
  initNavMobile() {
    const toggle = this.element.querySelector('.js-toggle');
    toggle.addEventListener('click', this.onToggleNav.bind(this));
  }

  /** Toggle la classe nav-is-active du menu hamburger */
  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }
}
