import Swiper from 'swiper/bundle';

/** Permet de créer une galerie d’images qui défile de haut en bas ou de droite à gauche */
export default class Carousel {
  /**
   * Création des variables globales pour le carousel
   * @param {HTMLElement} element - Élément
   */
  constructor(element) {
    this.element = element;
    this.options = {
      preloadImages: false,
      lazy: true,
      speed: 1000,
      effect: 'fade',
      draggable: false,
      slidesPerView: 1,
      marginBetween: 30,
      loop: true,

      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: 'yes',
      },
    };
    this.init();
  }

  /** Gère les data-components du carousel */
  setOptions() {
    if ('loop' in this.element.dataset) {
      this.options.draggable = true;
      this.options.effect = 'coverflow';

      this.options.coverflowEffect = {
        rotate: 30,
        slideShadows: false,
      };
    }
  }

  /** Initialise la méthode setOptions et crée un nouveau Swiper */
  init() {
    this.setOptions();
    new Swiper(this.element, this.options);
  }
}
